import { useCallback } from 'react'
import { DSNCDealsButton } from 'shared-definitions/types'
import clsx from 'clsx'
import styleUtils from 'shared-components/styles/util.module.css'
import styles from './NCDealsButton.module.css'
import EditButton from 'shared-components/service/EditButton'
import AccordionDeal, { StandardDealButton, StandardDealButtonProps } from '../layout/AccordionDeal'
import { AppEventsContext } from '../contexts/AppEventsContext'
import TextBadge from '../ui/TextBadge'

interface NCDealsButtonProps {
  data: DSNCDealsButton
}

const NCDealsButton: React.VFC<NCDealsButtonProps> = ({ data }) => {
  const { appEvent } = AppEventsContext.useContainer()
  return (
    <>
      <EditButton pLink={data.pLinkEdit} pLinkRequestUpdate={data.pLinkRequestUpdate} elevated />
      <div {...data.dataAttrs} className={clsx(styleUtils.nc, styles.container)}>
        {data.anchor && (
          <a id={data.anchor} className={styleUtils.anchor}>
            {data.buttons[0].link.label}
          </a>
        )}
        <AccordionDeal
          data={data.buttons}
          view="bordered"
          asButton={useCallback(
            (injectedProps: StandardDealButtonProps) => (
              <div className={styles.buttonBlock}>
                {data.textBadge && <TextBadge {...data.textBadge} className={styles.textBadge} />}
                <StandardDealButton data-sel="large-deal-button" {...injectedProps} />
              </div>
            ),
            [data.textBadge]
          )}
        />
        <AccordionDeal
          data={data.buttons}
          view="bordered"
          asButton={useCallback(
            (injectedProps: StandardDealButtonProps) => (
              <div className={styles.buttonBlock}>
                {data.textBadge && <TextBadge {...data.textBadge} className={styles.textBadge} />}
                <StandardDealButton
                  data-sel="large-deal-button"
                  {...injectedProps}
                  onClick={() => {
                    appEvent({
                      event: 'widget-interaction',
                      widgetType: 'Product Widget',
                      label: injectedProps.data.link.label,
                      url: injectedProps.data.link.pLink.href,
                    })
                  }}
                />
              </div>
            ),
            [data.textBadge]
          )}
        />
      </div>
    </>
  )
}

export default NCDealsButton
