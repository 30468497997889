import { DSNCSpecTable } from 'shared-definitions/types'
import styleUtils from 'shared-components/styles/util.module.css'
import styles from './NCSpecTable.module.css'
import ArrowBottomBird from 'shared-svg/ArrowBottomBird.svg'
import IconedButton from 'shared-components/buttons/IconedButton'
import clsx from 'clsx'
import { CSSProperties, useState } from 'react'
import EditButton from 'shared-components/service/EditButton'
import SpecTableNodes from '../ui/SpecTableNodes'
import { useScrollHeight } from '../hooks/use-scroll-height'

interface NCSpecTableProps {
  data: DSNCSpecTable
}

const NCSpecTable: React.VFC<NCSpecTableProps> = ({ data }) => {
  const { ref, height } = useScrollHeight<HTMLTableElement>()
  const [opened, setOpened] = useState(!data.collapsable)
  const toggleLabel = opened ? 'Collapse' : 'Expand'

  return (
    <div
      className={styleUtils.nc}
      style={{ '--nodes-scroll-height': `${height}px` } as CSSProperties}
    >
      <EditButton pLink={data.pLinkEdit} elevated pLinkRequestUpdate={null} />
      <div className={styles.title}>
        {data.title}
        {data.collapsable && (
          <button type="button" className={styles.titleButton} onClick={() => setOpened(s => !s)}>
            {toggleLabel}
            <ArrowBottomBird
              className={clsx(styles.buttonTilteIcon, {
                [styles.buttonTilteIconActive]: opened,
              })}
            />
          </button>
        )}
      </div>
      <div className={styles.nodesContainer}>
        <SpecTableNodes
          rows={data.rows}
          ref={ref}
          limitRows={data.limitRows}
          variant={opened ? 'expanded' : 'collapsed'}
        />
      </div>
      {data.collapsable ? (
        <IconedButton
          onClick={() => setOpened(s => !s)}
          className={clsx(styles.button, { [styles.buttonActive]: opened })}
        >
          {toggleLabel}
          <ArrowBottomBird className={styles.buttonIcon} />
        </IconedButton>
      ) : null}
    </div>
  )
}

export default NCSpecTable
