/* eslint-disable react/display-name */
import clsx from 'clsx'
import NCPoll from 'nc/NCPoll'
import NCRecommend from 'nc/NCRecommend'
import dynamic from 'next/dynamic'
import type { CSSProperties } from 'react'
import { componentMapper } from 'shared-code/component'
import NCAd from 'shared-components/nc/NCAd'
import NCAdClassed from 'shared-components/nc/NCAdClassed'
import NCAffilateSection from 'shared-components/nc/NCAffilateSection'
import NCAlert from 'shared-components/nc/NCAlert'
import NCApp from 'shared-components/nc/NCApp'
import NCAudioChart from 'shared-components/nc/NCAudioChart'
import NCBestProductGallery from 'shared-components/nc/NCBestProductGallery'
import NCColumns from 'shared-components/nc/NCColumns'
import NCDealsLatest from 'shared-components/nc/NCDealsLatest'
import NCDealsButton from 'shared-components/nc/NCDealsButton'
import NCDealsDetailed from 'shared-components/nc/NCDealsDetailed'
import NCDealsLarge from 'shared-components/nc/NCDealsLarge'
import NCDealsMedium from 'shared-components/nc/NCDealsMedium'
import NCDealsMediumCarousel from 'shared-components/nc/NCDealsMediumCarousel'
import NCDealsSimple from 'shared-components/nc/NCDealsSimple'
import NCExpandableImg from 'shared-components/nc/NCExpandableImg'
import NCFaq from 'shared-components/nc/NCFaq'
import NCGallery from 'shared-components/nc/NCGallery'
import NCImg from 'shared-components/nc/NCImg'
import NCImgComparison from 'shared-components/nc/NCImgComparison'
import NCLinkedImg from 'shared-components/nc/NCLinkedImg'
import NCMulti from 'shared-components/nc/NCMulti'
import NCNewsTitle from 'shared-components/nc/NCNewsTitle'
import NCOpinion from 'shared-components/nc/NCOpinion'
import NCPre from 'shared-components/nc/NCPre'
import NCPromoted from 'shared-components/nc/NCPromoted'
import NCQuote from 'shared-components/nc/NCQuote'
import NCScript from 'shared-components/nc/NCScript'
import NCSection from 'shared-components/nc/NCSection'
import NCSeeAlso from 'shared-components/nc/NCSeeAlso'
import NCSpecTable from 'shared-components/nc/NCSpecTable'
import NCString from 'shared-components/nc/NCString'
import NCTable from 'shared-components/nc/NCTable'
import NCTldr from 'shared-components/nc/NCTldr'
import NCTwitter from 'shared-components/nc/NCTwitter'
import NCUnsafeHtml from 'shared-components/nc/NCUnsafeHtml'
import NCLazy from 'shared-components/service/NCLazy'
import styleUtils from 'shared-components/styles/util.module.css'
import type { MapDiscriminatedComponent } from 'shared-definitions/types'
import type { DSNCAA } from 'types.aa.ds'
import NCProductsQuiz from 'shared-components/nc/NCProductsQuiz'
import { getds } from '../ds'
import styles from './NC.module.css'
import NCError from 'shared-components/nc/NCError'

const NCCarousel = dynamic(() => import('shared-components/nc/NCCarousel'), { ssr: false })
const NCLinkedButtons = dynamic(() => import('shared-components/nc/NCLinkedButtons'), {
  ssr: false,
})
const NCEmbedYoutube = dynamic(() => import('shared-components/nc/NCEmbedYoutube'), { ssr: false })
const NCEmbedTiktok = dynamic(() => import('shared-components/nc/NCEmbedTiktok'), { ssr: false })
const NCAudio = dynamic(() => import('shared-components/nc/NCAudio'), { ssr: false })
const NCMorePosts = dynamic(() => import('shared-components/nc/NCMorePosts'), { ssr: false })
const NCDealsSlider = dynamic(() => import('shared-components/nc/NCDealsSlider'), { ssr: false })
const NCVideoSlider = dynamic(() => import('shared-components/nc/NCVideoSlider'), { ssr: false })
const NCVideo = dynamic(() => import('shared-components/nc/NCVideo'), { ssr: false })
const NCPostsList = dynamic(() => import('nc/NCPostsList'), { ssr: false })
const NCFeedback = dynamic(() => import('nc/NCFeedback'), { ssr: false })
const NCNewsletter = dynamic(() => import('nc/NCNewsletter'), { ssr: false })
const NCFaqSubmission = dynamic(() => import('nc/NCFaqSubmission'), { ssr: false })

const map: MapDiscriminatedComponent<DSNCAA, 'resource'> = {
  'nc-error': NCError,
  'nc-unsafe-html': NCUnsafeHtml,
  'nc-products-quiz': NCProductsQuiz,
  'nc-app': NCApp,
  'nc-news-title': NCNewsTitle,
  'nc-best-product-title': null, // it was deliberately hidden
  'nc-best-product-gallery': NCBestProductGallery,
  'nc-spec-table': NCSpecTable,
  'nc-revcontent': feed => (
    <NCAdClassed
      {...{
        ...feed,
        data: { type: 'plain', resource: 'nc-ad-classed', visible: 'all', className: '' },
      }}
    />
  ),
  'nc-affilate-section': NCAffilateSection,
  'nc-recommend': NCRecommend,
  'nc-faq-submission': feed => (
    <NCLazy
      layout="raw"
      component={NCFaqSubmission}
      props={feed}
      className={clsx(styles.faqFeedbackLazy, styleUtils.nc)}
    />
  ),
  'nc-sponsored': NCMulti,
  'nc-faq': NCFaq,
  'nc-twitter': NCTwitter,
  'nc-deals-button': NCDealsButton,
  'nc-deals-simple': NCDealsSimple,
  'nc-deals-latest': NCDealsLatest,
  'nc-deals-detailed': NCDealsDetailed,
  'nc-deals-slider': feed => (
    <NCLazy
      layout="raw"
      component={NCDealsSlider}
      props={feed}
      className={clsx(styles.dealsSliderLazy, styleUtils.nc)}
    />
  ),
  'nc-deals-medium': NCDealsMedium,
  'nc-deals-medium-carousel': NCDealsMediumCarousel,
  'nc-deals-large': NCDealsLarge,
  'nc-ad-classed': NCAdClassed,
  'nc-linked-buttons': feed => (
    <NCLazy
      layout="raw"
      props={feed}
      component={NCLinkedButtons}
      className={clsx(styles.linkedButtonsLazy, styleUtils.nc)}
    />
  ),
  'nc-string': NCString,
  'nc-newsletter': feed => (
    <NCLazy layout="raw" component={NCNewsletter} props={feed} className={styles.newsletterLazy} />
  ),
  'nc-poll': NCPoll,
  'nc-more-posts': feed => (
    <NCLazy
      className={clsx(styleUtils.nc, styles.moreLazy, {
        [styles.moreLazyHome]: feed.tpl === 'home',
      })}
      component={NCMorePosts}
      props={feed}
      layout="raw"
    />
  ),
  'nc-section': NCSection,
  'nc-linked-img': NCLinkedImg,
  'nc-img': NCImg,
  'nc-alert': NCAlert,
  'nc-quote': NCQuote,
  'nc-see-also': NCSeeAlso,
  'nc-table': NCTable,
  'nc-embed-tiktok': feed => (
    <NCLazy
      className={clsx(styles.tiktokEmbed, styleUtils.nc)}
      layout="raw"
      component={NCEmbedTiktok}
      props={feed}
    />
  ),
  'nc-embed-youtube': feed => (
    <NCLazy
      layout="raw"
      component={NCEmbedYoutube}
      props={feed}
      className={clsx(styles.videoLazy, styleUtils.mobileFull, styleUtils.nc)}
    />
  ),
  'nc-img-comparison': feed => (
    <NCLazy layout="responsive" ratio={feed.data.size} component={NCImgComparison} props={feed} />
  ),
  'nc-opinion': NCOpinion,
  'nc-gallery': NCGallery,
  'nc-carousel': feed => (
    <NCLazy
      layout="raw"
      component={NCCarousel}
      style={
        {
          '--w': feed.data.items[0].size?.w ?? 16,
          '--h': feed.data.items[0].size?.h ?? 9,
        } as CSSProperties
      }
      props={feed}
      className={clsx(styles.carouselLazy, styleUtils.nc, {
        [styles.carouselLazyTitled]: feed.data.title !== null,
      })}
    />
  ),
  'nc-tldr': NCTldr,
  'nc-ad': feed => (
    <NCAd
      {...feed}
      context={{
        getAds: async args => {
          const dataSource = await getds({ ts: args.ts })
          const { data: ads } = await dataSource.getAds(args.id, {
            type: args.type,
            postId: args.postId ?? undefined,
          })
          return ads
        },
      }}
    />
  ),
  // 'nc-ad': feed => (
  //   <NCLazy
  //     layout="raw"
  //     props={feed}
  //     component={NCAd}
  //     className={clsx(styles.adLazy, styles.containerLazy)}
  //   />
  // ),
  'nc-columns': NCColumns,
  'nc-audio-chart': NCAudioChart,
  'nc-pre': NCPre,
  'nc-script': NCScript,
  'nc-promoted': NCPromoted,
  'nc-multi': NCMulti,
  'nc-video': feed => (
    <NCLazy
      layout="raw"
      component={NCVideo}
      props={feed}
      className={clsx(styles.videoLazy, styleUtils.mobileFull, styleUtils.nc)}
    />
  ),
  'nc-audio': feed => (
    <NCLazy
      layout="raw"
      component={NCAudio}
      props={feed}
      className={clsx(styles.audioLazy, styleUtils.nc)}
    />
  ),
  'nc-posts-list': feed => (
    <NCLazy
      layout="raw"
      component={NCPostsList}
      props={feed}
      className={clsx(styles.postsListLazy, {
        [styles.postsListLargeLazy]: feed.data.size === 'large',
        [styles.postsListSmallLazy]: feed.data.size === 'small',
      })}
    />
  ),
  'nc-feedback': feed => (
    <NCLazy
      component={NCFeedback}
      props={feed}
      layout="raw"
      className={clsx(styleUtils.nc, styles.feedbackLazy)}
    />
  ),
  'nc-video-slider': feed => (
    <NCLazy
      component={NCVideoSlider}
      props={feed}
      layout="raw"
      className={clsx(styleUtils.nc, styles.moreLazyHome)}
    />
  ),
  'nc-expandable-img': NCExpandableImg,
}

const NC = componentMapper(map)

export default NC
